#addClueTab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0px var(--side-padding);


    & h3{
        text-align: center;
    }

    & .inputContainer{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 110px;
        width: 100%;
        text-align: center;

        margin-bottom: 250px;


        &.clueUnlocked{
            & .inputWrapper{
                display: none;
            }

            & .clueName{
                opacity: 1;
                animation: fadeIn 3s;
            }
        }

        & .inputWrapper{
            position: relative;
            width: 100%;

            & input{
                width: 100%;
                color: var(--main-color);
                background-color: transparent;
                font-size: var(--standard-font-size);
                border: none;
                border-bottom: 1px solid var(--main-color);
                text-align: center;
    
                &.wrong{
                    animation: inputWrongAnswer 4s; 
                }
    
                &:focus{
                    outline: none;
                }
            }

            & button{
                position: absolute;
                right: 0;
                bottom: 2px;

                background: url(../../../../../public/media/icons/check.svg);
                right: 0;
                height: 35px;
                width: 35px;
                background-repeat: no-repeat;
                background-size: contain;

                filter: var(--main-color-filter);

                &.wrong{
                    animation: buttonWrongAnswer 4s; 
                }
            }
        }

        & .clueContainer{
            position: absolute;
            top: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 110px;

            border-radius: 50%;

            &.clueUnlocked{
                top: 20px;
                animation: floatUp 2.5s;
                & .cover{
                    opacity: 0;
                    animation: fadeOut 2s;
                }
                & .lock{
                    opacity: 0;
                    animation: fadeOut 2s;
                }
            }

            & .clueIconWrapper{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 110px;
                width: 110px;
                & .clueIcon{
                    height: 100%;
                    width: 100%;
                    border: 1px solid var(--main-color);
                    background-color: #130f0f;
                    border-radius: 50%;
                    
                }
    
                & .cover{
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    background-color: #000000cf;
                    border-radius: 50%;
                }          
    
                & .lock{
                    position: absolute;
                    height: 100%;
                    width: 100%;
    
                    background-repeat: no-repeat;
                    background-size: 50%;
                    background-position: center;
    
                    &.unlocked{
                        background-image: url(../../../../../public/media/icons/unlocked.png);
                    }
                    &.locked{
                        background-image: url(../../../../../public/media/icons/locked.png);
    
                    }
                }

            }

        }

        & .clueName{
            opacity: 0;
            font-family: var(--handwritten-font);
            margin-top: 20px;
            font-size: var(--medium-font-size);
        }
        & .areas{
            animation: fadeIn 2s;
            color: var(--main-color);
            margin: 5px 0px;
        }

    }
}


@keyframes buttonWrongAnswer {
    0% {
        filter: var(--error-color-filter);
    }
    99% {
        filter: var(--error-color-filter);
    }
    100%{
        filter: var(--main-color-filter);
    }
    
}
@keyframes inputWrongAnswer {
    0% {
        border-bottom: 1px solid var(--error-color);
        color: var(--error-color);
    }
    75% {
        border-bottom: 1px solid var(--error-color);
        color: var(--error-color);
    }
    100%{
        border-bottom: 1px solid var(--main-color);
        color: var(--main-color);
        
    }
    
}

@keyframes fadeOut {
    0%{opacity: 1;}
    100%{opacity: 0;}
}
@keyframes fadeIn {
    0%{opacity: 0;}
    80%{opacity: 0;}
    100%{opacity: 1;}
}
@keyframes floatUp {
    0%{top: 150px}
    50%{top: 150px}
    100%{top: 20px;}
}