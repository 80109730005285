& .stats{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 20px;

    height: calc(100% - 160px);

    & li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 80px;
        font-size: 24px;

        width: 200px;

        & .icon{
            height: 45px;
            width: 45px;

            filter: var(--main-color-filter);


            &.time{
                background: url(../../../../../public/media/icons/hourglass.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            &.clues{
                background: url(../../../../../public/media/icons/clues.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            &.location{
                background: url(../../../../../public/media/icons/map.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            &.ending{
                background: url(../../../../../public/media/icons/arrow.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }
    }
}