#loginRegister{
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    width: 100%;

    & form{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 50px;

        height: 100%;
        width: 100%;
        max-width: 400px;
        /* max-height: 500px; */

        padding: var(--side-padding);



        & .topSection{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 300px;

            background: url(../../../public/media/general/puzzlebox.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            & .messageContainer{
                height: 40px;
            }
        }

        & .bottomSection{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            & .inputs{
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 40px;            


                width: 100%;
    
    
                & input{
                    background-color: transparent;
                    padding: 5px;
                    border: none;
                    border-bottom: 1px solid white;
                    color: white;
                    font-size: var(--standard-font-size);
                    &:focus{
                        outline: none;
                    }
                }
            }

            & .loginRegisterButton{
                width: 100px;
            }
    
            & .switch{
                background-color: transparent;
                border: none;
                font-size: var(--standard-font-size);

                & span{
                    color: var(--main-color);
                }
            }

        }

    }
}