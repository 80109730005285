#map{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    & .mapWrapper{
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100vw - var(--side-padding)*2);
        height: calc(100vw - var(--side-padding)*2 + 40px);

        height: 100vh;
        width: 100vw;

        z-index: 1;

        & .leaflet-container{
            & .leaflet-tile-pane{
                filter: var(--leaflet-tile-filter, none);
            }
        }

        & .loadingMapText{
            position: absolute;
        }
    }
}
