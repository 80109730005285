#leaderboard{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--side-padding);
    padding-top: 0;

    & .title{
        margin-top: var(--counter-height);
    }

    & .board{
        height: 100%;
        max-height: 100%;
        overflow: scroll;

        & li{
            display: flex;
            justify-content: space-between;
            font-size: var(--standard-font-size);

            margin: 10px 0px;
            padding: 10px;

            border-bottom: 1px solid var(--accent-color);
        }
    }
}