#clues{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 100%;

    & .listOfClues{
        display: flex;
        flex-direction: column;
        
        width: 100%;

        overflow-y: scroll;
    }

    & .addClueButton{
        margin-bottom: 100px;
        margin-top: 20px;
    }
}