#suspects{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 100%;

    & .listOfSuspects{
        display: flex;
        flex-direction: column;

        width: 100%;

        overflow-y: scroll;
    }

    & .buttonWrapper{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    

}