.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    & .loadingSymbol{
        height: 90px;
        width: 90px;

        background: url(../../../public/media/general/puzzlebox.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        animation: spin 2.2s infinite;
    }
}

@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}