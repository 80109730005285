.audioButton{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background-color: var(--nav-background);

    & .icon{
        height: 40px;
        width: 40px;
        background: url(../../../../../public/media/icons/soundfile.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        filter: var(--main-color-filter);

        &.playing{
            animation: blink 2s infinite;
        }
    }
}

@keyframes blink {
    0%{opacity: 1;}
    50%{opacity: 0.5;}
    100%{opacity: 1;}
    
}