.commonLi{
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--accent-color);
    border-bottom: 1px solid var(--accent-color);

    padding: 10px var(--side-padding) 10px var(--side-padding) ;

    & .wrapper{
        display: flex;
        align-items: center;
        gap: 20px;

        & .image{
            height: 60px;
            width: 60px;
            min-height: 60px;
            min-width: 60px;
            border-radius: 50%;
            border: 1px solid var(--main-color);
            background-color: #130f0f;
        }

        & .name{
            font-size: var(--standard-font-size);
            font-family: var(--handwritten-font);
        }
    }

    & .interactionButton{
        height: 35px;
        width: 35px;
        min-height: 35px;
        min-width: 35px;
        padding: 0px;

        margin-left: 5px;

        border-radius: 50%;
        border: 1px solid var(--main-color);
        background: url(../../../../../public/media/icons/info.png);
        background-position: center;
        background-size: contain;

    }
}