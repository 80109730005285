.tab{
    position: absolute;
    top: 0;
    top: var(--counter-height);
    height: calc(100% - var(--menu-height) - var(--counter-height));
    width: 100%;
    overflow-y: scroll;

    animation: moveIn 1s;

    & .tabContent{
        height: 100%;
    }

    & .tabContentContainer {
        height: calc(100% - var(--return-button-height));

    }
}