#mainMenu{
    position: absolute;
    top: 0;
    left: 0%;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20px;

    height: 100svh;
    width: 100vw;

    animation: moveIn 2s;
    padding: var(--side-padding);

    & .titleText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 300px;
        width: 300px;

        background: url(../../../public/media/general/puzzlebox.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    & ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;


        & button{
            font-size: var(--medium-font-size);
        }
    }

    & .user{
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: var(--medium-font-size);
        font-family: var(--handwritten-font);
    }
}