#casefile{
    position: relative;
    height: 100%;

    overflow: scroll;

    & .description{
        max-height: 100%;
        overflow: scroll;

        font-size: var(--standard-font-size);
        font-family: var(--handwritten-font);
        white-space: preserve-breaks;

        padding: 0px var(--side-padding);
        padding-bottom: calc(var(--interaction-button-height) + 30px);
    }

    & .buttonWrapper{
        justify-content: space-between;
        bottom: 0px;
        padding: 0px var(--side-padding);

        width: 100%;
    }
}