#counter{
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--side-padding);

    height: var(--counter-height);

    & >div:not(.soundControl){
        width: 80px;
    }

    & .timer{
        color: var(--error-color);
        font-size: 22px;
    }

    & .soundControl{
        width: 40px;
        height: 40px;


        & .controlWrapper{
            position: relative;
            height: 100%;
            width: 100%;
            filter: var(--main-color-filter);


            & >div{
                position: absolute;
                height: 100%;
                width: 100%;
            }

            & .grammophone{
                background: url(../../../../public/media/icons/soundfile.svg);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0.6;
            }



            &.paused{
                & .grammophone{
                    opacity: 0.3;
                }

                & .button{
                    background: url(../../../../public/media/icons/play.svg);
                    background-position: center;
                    background-size: 45%;
                    background-repeat: no-repeat;
                }
            }
            &.playing{

                & .button{
                    background: url(../../../../public/media/icons/pause.svg);
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

        }
    }

    & .return{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 80px;
        height: 60px;

        background-color: transparent;
        border: none;
        color: var(--main-color);


        font-size: var(--standard-font-size);
        font-family: var(--standard-font);

        & .arrow{
            height: 100%;
            width: 100%;
            max-width: 300px;
            background-image: url("../../../../public/media/icons/returnArrow.svg");
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: left;

        }
    }
}