#Results{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    height: 100%;

    text-align: center;

    overflow: hidden;

    & .contentContainer{
        height: calc(100% - 160px);
        overflow: scroll;
    }

    & h2{
        margin-top: var(--counter-height);
    }

    & .buttons{
        position: absolute;
        bottom: 0;
        width: 100%;
        & button{
            margin: 10px 0px;
            width: 80%;
        }
    }
}