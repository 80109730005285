:root{
  /* COLORS */
  --main-color: #B29865;
  --accent-color: #F2E0E0;
  --accent-color-disabled: #917A7A;;
  --error-color: #AC3434;
  --nav-background: rgba(0, 0, 0, 0.827);

  /* FILTERS */
  --main-color-filter: invert(70%) sepia(35%) saturate(433%) hue-rotate(1deg) brightness(83%) contrast(84%);
  --accent-color-filter: invert(93%) sepia(6%) saturate(413%) hue-rotate(313deg) brightness(99%) contrast(92%);
  --error-color-filter: invert(34%) sepia(23%) saturate(7344%) hue-rotate(340deg) brightness(69%) contrast(87%);
  --leaflet-tile-filter: grayscale(1) brightness(.6) invert(1) contrast(1.8) brightness(0.5);

  /* SIZING */
  --menu-height: calc(var(--menu-button-size) + var(--top-padding));
  --menu-button-size:45px;
  --counter-height: calc(var(--top-padding) + 40px);
  --title-height: 55px;
  --return-button-height: 60px;
  --interaction-button-height: 50px;

  /* PADDING */
  --side-padding: 20px;
  --top-padding: 30px;
  --bottom-padding: 30px;

  /* FONTS */
  --handwritten-font: font-family: 'Courier New', Courier, monospace;
  --standard-font: Alegreya;

  /* FONT SIZING*/
  --standard-font-size:18px;
  --medium-font-size:24px;
  --large-font-size:28px;
  --extraLarge-font-size:34px;
}

@font-face {
  font-family: typeWriter;
  src: url(../public/media/fonts/SpecialElite-Regular.ttf);
}
@font-face {
  font-family: Alegreya;
  src: url(../public/media/fonts/Alegreya-Regular.ttf);
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: var(--accent-color);
  font-family: var(--standard-font);
}

body{
  height: 100svh;
  width: 100vw;
}

#main{
  position: relative;
  height: 100svh;
  width: 100vw;
  background-image: url(../public/media/general/bg.png);
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  overflow: hidden;
}

/* GENERAL ELEMENTS */

ul{
  list-style-type: none;
}

img{
  font-size: 0px;
}

button{
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* GENERAL H1-H6 FONT SIZE */

h1{
  font-size: var(--extraLarge-font-size);
}
h2{
  font-size: var(--large-font-size);

}
h3{
  font-size: var(--medium-font-size);
  
}
p{
  font-size: var(--standard-font-size);
}


/* Quick Access styling */

.interactionButton{
  border-radius: 10px;
  color: var(--main-color);
  background-color: var(--nav-background);

  font-size: var(--standard-font-size);
  font-family: var(--standard-font);

  height: var(--interaction-button-height);
  padding: 10px var(--side-padding);

  &.soundFile{
    background: url(../public/media/icons/soundfile.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: var(--main-color-filter);
  }
}

.buttonWrapper{
  display: flex;
  position: absolute;
  bottom: 0;
  height: calc(var(--interaction-button-height) + 10px);
  overflow: hidden;

  &.hidden{

    & .interactionButton{
      transform: translateY(60px);
      animation: buttonDissappear 1.5s;
    }
  }

  &:not(.hidden) {
    & .interactionButton{
      transform: translateY(0px);
      animation: buttonAppear 1.5s;
    }
  }
}

.frame{
  background-image: url(../public/media/general/frame.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  filter: var(--main-color-filter);
}

.title{
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--title-height);
  font-size: var(--large-font-size);
  font-family: var(--standard-font);
  color: var(--main-color);
}

.colorMain{
  color: var(--main-color);
}
.colorAccent{
  color: var(--accent-color);
}
.colorError{
  color: var(--error-color);
}

/* ANIMATION */

@keyframes moveIn {

  0%{
      left: 100%;
  }
  100%{
      left: 0%;
  }
  
}

@keyframes  buttonAppear {
  0%{transform: translateY(60px);}
  100%{transform: translateY(0px);}
}
@keyframes  buttonDissappear {
  0%{transform: translateY(0px);}
  100%{transform: translateY(60px);}
}