#howToPlay{
    position: relative;
    height: 100%;

    .pageContentContainer{
        height: 100%;
    }

    & .pageContainer{
        width: 100%;
        height: calc(100% - var(--menu-height));
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        

        & .pageContentContainer{
            height: 100%;

            & .iconContainer{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                height: 25%;
                padding: var(--side-padding);

                & .icon{
                    height: 80px;
                    width: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;

                    & img{
                        height: 80%;
                        width: 80%;
                        filter: var(--main-color-filter);
                        animation: bounce 2.5s infinite;
                    }
                }
            }
            & .instructionsContainer{
                height: 75%;
                padding: var(--side-padding);
                white-space: preserve-breaks;

                & .icon{
                    color: rgba(0, 0, 0, 0);
                    font-size: 30px;
                    margin: 0px 4px;

                    &.soundfile{
                        background: url(../../../../public/media/icons/soundfile.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        filter: var(--main-color-filter);
                    }

                    &.clue{
                        background: url(../views/Map/marker.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
        }

        & .banner{
            text-align: center;
            padding: 10px;
        }
    }

    & .nav{
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        background-color: var(--nav-background);

        height: var(--menu-height);
        width: 100%;
        border-top: 1px solid var(--main-color);
        font-size: var(--standard-font-size);

        & >button{
            font-size: var(--standard-font-size);

        }


        & .pageSetter {
            display: flex;
            justify-content: center;
            align-items: center;

            & button{
                width: var(--menu-button-size);
                height: var(--menu-button-size);
                background-image: url(../../../../public/media/icons/arrow.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                filter: var(--main-color-filter);
                color: var(--main-color);


                &.previous{
                    transform: rotate(180deg);
                }
            }
        }

        & .begin:not(.active){
            color: var(--accent-color-disabled);
        }
    }
}

@keyframes bounce {
    0%{
        transform: translateY(-6px);
    }
    50%{
        transform: translateY(6px);
    }
    100%{
        transform: translateY(-6px);
    }
}