.conclusion{
    /* height: 100%; */
    padding: var(--side-padding);

    & .topContainer{
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        margin-bottom: 20px;
    }

    & p{
        text-align: left;
        margin-top: 40px;
    }
}