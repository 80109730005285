.suspectsTab{
    height: 100%;

    color: var(--accent-color);


    .topContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        & .topInfoWrapper{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 110px;
            width: 100%;

            & .image {
                position: absolute;
                width: 110px;
                height: 110px;
                border-radius: 50%;
            }

            & .audioButton{
                margin-left: 180px;
            }

        }

        & .name {
            font-size: var(--large-font-size);
            font-family: var(--handwritten-font);
            padding: var(--side-padding);
            text-align: center;
        }
    }
    .descriptionContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0px var(--side-padding);
        & p{
            font-size: var(--standard-font-size);
            white-space: preserve-breaks;
            font-family: var(--handwritten-font);

            &.description{
                margin-top: 20px;
            }
        }


    }
}