.view{
    height: calc(100% - var(--menu-height) - var(--counter-height));
    /* padding: 0px var(--side-padding); */

    & .title{
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--title-height);
        font-size: var(--medium-font-size);
        font-family: var(--standard-font);
    }

    & .viewContent{
        height: calc(100% - var(--title-height));
    }
}