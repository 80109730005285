.makeAccusationTab{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .topContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        height: 200px;


        & .chooseSuspect{
            display: flex;
            align-items: center;

            & button{
                height: 60px;
                width: 60px;
                background: url(../../../../../public/media/icons/arrow.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 25px;
                filter: var(--main-color-filter);

                &.previous{
                    transform: rotate(180deg);
                }
            }
            

            & .image {
                width: 110px;
                height: 110px;
                border-radius: 50%;
            }
        }

        & .name {
            text-align: center;
            font-size: var(--large-font-size);
            font-family: var(--handwritten-font);
            padding: var(--side-padding);

        }

    }
    .cluePicker {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);

        gap: 10px;

        width: 80vw;
        height: 80vw;
        max-height: 350px;
        max-width: 350px;

        & >div{
            align-self: center;
            height: 100%;
            width: 100%;
        }

        & .accusationFeedback{
            display: flex;
            justify-content: center;
            align-items: center;
            grid-column: 1/4;
            grid-row: 1/2;

            font-size: var(--standard-font-size);
        }

        & .cluePicked{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            & div{
                position: absolute;

                height: 90%;
                width: 90%;
                border-radius: 50%;

                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;


            }


            &.wrong{
                & .frame{
                    filter: var(--error-color-filter);
                }

                & .bg{
                    filter: var(--error-color-filter);
                }

                &.cluePickedOne{
                    & .clue{
                        opacity: 0;
                        animation: fadeOut 4s;
                    }
                }
                &.cluePickedTwo{
                    & .clue{
                        opacity: 0;
                        animation: fadeOut 5s;
                    }

                }
                &.cluePickedThree{
                    & .clue{
                        opacity: 0;
                        animation: fadeOut 6s;
                    }
                }

            }

            & .frame{
                position: absolute;
                height: 140%;
                width: 140%;
            }

            & .bg{                
                background: url(../../../../../public/media/icons/clues.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 30%;
                filter: var(--main-color-filter);
            }

            & .clue{
                z-index: 2;
                &.chosen{
                    background-color: #130f0f;
                }
            }

            &.cluePickedOne{
                grid-column: 1/2;
                grid-row: 3/2;
            }
            &.cluePickedTwo{
                grid-column: 3/4;
                grid-row: 3/2;
            }
            &.cluePickedThree{
                grid-column: 2/3;
                grid-row: 3/4;
            }
        }


        & .accusationCounter{
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--main-color);
            color: var(--main-color);

            font-size: var(--medium-font-size);
            
            grid-column: 2/3;
            grid-row: 1/2;

            &.wrong{
                animation: test 5s;
            }
        }

    }
}


@keyframes fadeOut {
    0%{
        opacity: 1;
    }

    40%{
        opacity: 1;
    }

    100%{
        opacity: 0;

    }
}
@keyframes test {
    0%{
        color: var(--error-color);
    }

    80%{
        color: var(--error-color);
    }
    100%{
        color: var(--main-color);
    }

}