#nav{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    height: var(--menu-height);
    width: 100%;

    background-color: var(--nav-background);
    border-top: 1px solid var(--main-color);



    z-index: 2;


    & .timer{
        position: absolute;
        bottom: 0;
        font-size: 20px;

        &.low{
            color: var(--error-color);
        }
    }

    & button{
        border-radius: 50%;
        background-color: transparent;
        /* border: 2px solid var(--accent-color); */
        height: var(--menu-button-size);
        width: var(--menu-button-size);
        color: white;

        & .bg{
            height: 100%;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            filter: var(--accent-color-filter);
            
        }

        /* add icons */
        &.Casefile .bg{
            background-image: url(../../../../public/media/icons/casefile.svg);
        }
        &.Clues .bg{
            background-image: url(../../../../public/media/icons/clues.svg);
        }
        &.Suspects .bg{
            background-image: url(../../../../public/media/icons/suspects.svg);
        }
        &.Map .bg{
            background-image: url(../../../../public/media/icons/map.svg);
        }

        &.selected{
            /* border: 2px solid var(--main-color); */

            & .bg{
                filter: var(--main-color-filter);
            }
        }

    }
}